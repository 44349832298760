import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";

export default {
  state: {
    grid: null,
  },
  mutations: {
    setGrid(state, payload) {
      state.grid = payload;
    },
  },
  actions: {
    setGrid({ commit }, payload) {
      commit("setGrid", payload);
    },
    getGrid({ commit }, { presetId, date, duration, loader }) {
      const url = `${apiEndpoints.company.bookingGrid}/${presetId}?date=${date}&duration=${duration}`;
      if (loader == true) {
        commit("loader/setScreenLoading", true, { root: true });
      }
      return httpServiceAuth
        .get(url)
        .then((response) => {
          commit("setGrid", response.data);
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
};
